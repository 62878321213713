import HomeHeaderOther from "@/components/HomeHeader/indexOther";
import { LocationInfo } from "@/data/LocationInfo";
import { PersistContext } from "@/data/PersistProvider";
import { LanguageContext } from "@/data/LanguageProvider";
import { useEnhancedHistory } from "@/data/RouterProvider";
import { SendCMD } from "@/utils/HTTPRequest";
import { ChannelRemind } from "@/utils/UrlHelper";
import React, { useEffect, useRef, useState } from "react";
import style from "./style.less";
import { LocalConfig } from "@/config";

function filterNonDigits(input) {
  const result = input.replace(/\D/g, "");
  return result;
}

const Forgot = () => {
  const history = useEnhancedHistory();
  const inputRef = useRef();
  const pageSeedRef = useRef(Math.random());
  const [phoneError, setPhoneError] = React.useState("");
  const [phoneErrorShakeNum, setPhoneErrorShakeNum] = useState(0);
  const { getPersist, setPersist } = React.useContext(PersistContext);
  const { langText } = React.useContext(LanguageContext);
  const nowRegionInfo = LocationInfo();
  const [phone, setPhone] = React.useState(getPersist("last_phone") ? getPersist("last_phone") : "");

  const [formVilidated, setFormVilidated] = React.useState(false);

  // 显示跳转注册提示
  const [phoneIsReg, setPhoneIsReg] = React.useState(true);

  const PhoneErrorTextConst = langText("verify_tips_3");
  const PhoneStartWith0ErrorTextConst = langText("verify_tips_12");

  const formSubmit = (e) => {
    if (!phone) {
      if (!phoneError) {
        setPhoneError(PhoneErrorTextConst);
      }
      setPhoneErrorShakeNum(phoneErrorShakeNum + 1);
      return;
    }

    SendCMD("checkPhoneRegistered", {
      role_account: nowRegionInfo.phoneStartsWith + phone,
    }).then((res) => {
      if (!res.status) {
        setPhoneError(res.errdesc);
        return;
      } else {
        if (!res.is_req) {
          setPhoneIsReg(false);
          setPhoneError(langText("verify_tips_20"));
          return;
        } else {
          //发送验证码请求
          SendCMD("sendVerificationCode", {
            phone: nowRegionInfo.phoneStartsWith + phone,
            page: "Forgot",
            seed: pageSeedRef.current,
            guest_id: window.guestId ? window.guestId() : "",
          }).then((res) => {
            if (!res.status) {
              setPhoneError(res.errdesc);
              return;
            } else {
              setPersist("last_phone", phone);
              history.replace(ChannelRemind("/verify"));
            }
          });
        }
      }
    });

    e.preventDefault();
  };

  useEffect(() => {
    if (phone && !phoneError) {
      setFormVilidated(true);
    } else {
      setFormVilidated(false);
    }
  }, [phone, phoneError]);

  return (
    <div class={style.forgot_container}>
      <HomeHeaderOther backToUrl={"/login"} title={langText("profile_title_6")} />
      <div class={style.forgot_top}>{langText("profile_title_6")}</div>
      <div class={style.forgot_reset_intro}>{langText("profile_tips_5")}</div>
      <div class={style.login_form}>
        <div class={style.login_form_input}>
          <div class={style.phone_before}>
            <img src={nowRegionInfo.flag} />
          </div>
          <input
            class={style.auth_input}
            ref={inputRef}
            type="tel"
            maxLength={nowRegionInfo.phoneNumberDigits || 9}
            placeholder={langText("register_tips_1")}
            style={{ marginLeft: "0.52rem", fontSize: "1.1rem" }}
            defaultValue={getPersist("last_phone")}
            onChange={(e) => {
              let value = e.target.value;
              value = filterNonDigits(value);
              if (inputRef.current && inputRef.current.value !== value) {
                inputRef.current.value = value;
              }
              let regexString = `^[1-9]{1}[0-9]{${(nowRegionInfo.phoneNumberDigits || 9) - 1}}$`;
              let regex = new RegExp(regexString);
              if (value.startsWith("0")) {
                setPhone("");
                setPhoneError(PhoneStartWith0ErrorTextConst);
              } else if (regex.test(value)) {
                setPhone(value);
                setPhoneError("");
              } else if (value.length > 0) {
                setPhone("");
                setPhoneError(PhoneErrorTextConst);
              } else {
                setPhone("");
                setPhoneError("");
              }
            }}
          />
        </div>
        <div
          class={`${style.login_form_error} ${phoneErrorShakeNum > 0 ? style.shake : ""}`}
          key={`phoneErrorShakeNum${phoneErrorShakeNum}`}
        >{phoneError}</div>
        <div
          class={style.login_btn + (!formVilidated ? ` ${style.login_btn_disabled}` : "")}
          type="submit"
          onClick={formSubmit}
        >
          {langText("profile_btn_11")}
        </div>
        {!phoneIsReg && (
          <div class={style.code_btn}>
            {langText("verify_tips_26")}&nbsp;
            <span onClick={() => {
              setPersist("isLogin", false)
              history.replace(ChannelRemind("/login"));
            }}>{langText("verify_tips_25")}</span>
          </div>
        )}
      </div>
      <div class={style.footer}>
        <img src={require("@/assets/images/home/18_plus.png").default} />
        <span>© {LocalConfig.gameName} {langText("common_tips_1")}</span>
      </div>
    </div>
  );
};

export default Forgot;
