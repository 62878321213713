const payments = [
  {
    text: "MTN",
    enable: true,
    type: "MTNZM",
    merchantId: 10, 
    img: "../assets/images/home/MTN.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 760 && phoneHeader <= 769) ||
        (phoneHeader >= 770 && phoneHeader <= 779) ||
        (phoneHeader >= 780 && phoneHeader <= 789)
      ) {
        return true;
      }
      return false;
    },
  },
  {
    text: "Airtel",
    enable: true,
    merchantId: 10,
    type: "AIRTELZM",
    img: "../assets/images/home/airtel_money.png",
    checkPrecedence: (phone) => {
      let phoneHeader = parseInt(phone.substring(0, 3), 10);
      if (
        (phoneHeader >= 700 && phoneHeader <= 709) ||
        (phoneHeader >= 750 && phoneHeader <= 759)
      ) {
        return true;
      }
      return false;
    },
  },
  {
    text: "30",
    enable: true,
    merchantId: 30,
    type: "",
    img: "",
    checkPrecedence: (phone) => {
      return false;
    },
  },
  {
    text: "Test",
    enable: true,
    merchantId: 999,
    type: "TEST",
    img: "",
    checkPrecedence: (phone) => {
      return false;
    },
  },
];
export default {
  payments: payments.filter((payment) => payment.enable),
};
