import {LocalConfig} from "@/config";
export const LocationDefine = {
  KE: {
    name: "Kenya",
    code: "KE",
    currency: "KES",
    currencyName: "Kenyan Shilling",
    phoneStartsWith: "254",
    phoneNumberDigits: 9,
    lang: "en",
    flag: require("@/assets/images/header/flag_kenya.png").default,
    domain: "gamelaka.com",
    url: "https://gamelaka.com",
  },
  UG: {
    name: "Uganda",
    code: "UG",
    currency: "UGX",
    currencyName: "Ugandan Shilling",
    phoneStartsWith: "256",
    phoneNumberDigits: 9,
    lang: "en",
    flag: require("@/assets/images/header/flag_uganda.png").default,
    domain: "laka777.com",
    url: "https://laka777.com",
  },
  GH: {
    name: "Ghana",
    code: "GH",
    currency: "GHS",
    currencyName: "Ghanaian Cedi",
    phoneStartsWith: "233",
    phoneNumberDigits: 9,
    lang: "en",
    flag: require("@/assets/images/header/flag_ghana.png").default,
    domain: "lakagh.com",
    url: "https://lakagh.com",
  },
  ZM: {
    name: "Zambia",
    code: "ZM",
    currency: "ZMW",
    currencyName: "Zambian kwacha",
    phoneStartsWith: "260",
    phoneNumberDigits: 9,
    lang: "en",
    flag: require("@/assets/images/header/flag_zambia.png").default,
    domain: "gamelaka.com",
    url: "https://gamelaka.com",
  },
  IN: {
    name: "India",
    code: "IN",
    currency: "INR",
    currencyName: "Indian rupee",
    phoneStartsWith: "91",
    phoneNumberDigits: 10,
    lang: "en",
    flag: require("@/assets/images/header/flag_india.png").default,
    domain: "suwin8.com",
    url: "https://suwin8.com",
  },
  IN2: {
    name: "India",
    code: "IN",
    currency: "INR",
    currencyName: "Indian rupee",
    phoneStartsWith: "91",
    phoneNumberDigits: 10,
    lang: "en",
    flag: require("@/assets/images/header/flag_india.png").default,
    domain: "gameluka.com",
    url: "https://gameluka.com",
  },
  PK: {
    name: "Pakistan",
    code: "PK",
    currency: "PKR",
    currencyName: "Pakistan Rupee",
    phoneStartsWith: "92",
    phoneNumberDigits: 10,
    lang: "en",
    flag: require("@/assets/images/header/flag_pakistan.png").default,
    domain: "luka555.com",
    url: "https://luka555.com",
  },
  UG2: {
    name: "Uganda",
    code: "UG",
    currency: "UGX",
    currencyName: "Ugandan Shilling",
    phoneStartsWith: "256",
    phoneNumberDigits: 9,
    lang: "en",
    flag: require("@/assets/images/header/flag_uganda.png").default,
    domain: "laka777.com",
    url: "https://laka777.com",
  },
};

/**
 *
 * @param {string | null} locationKey
 * @returns {name, code, currency, currencyName, phoneStartsWith, lang, flag, domain, url}
 */
export function LocationInfo(locationKey) {
  if (!locationKey) {
    locationKey = LocalConfig.locationKey;
  }
  let upperLocationKey = locationKey.toUpperCase();
  if (!LocationDefine[upperLocationKey]) {
    let keys = Object.keys(LocationDefine);
    upperLocationKey = keys[keys.length - 1];
  }
  return LocationDefine[upperLocationKey];
}
